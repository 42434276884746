.snow-container {
  position: fixed;
  top: 0;
}

.snow {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #e9e7e7;
  border-radius: 50%;
}
.snow:nth-child(1) {
  opacity: 0.1506;
  transform: translate(79.2008vw, -10px) scale(0.6534);
  animation: fall-1 29s -9s linear infinite;
}
@keyframes fall-1 {
  36.985% {
    transform: translate(81.0369vw, 36.985vh) scale(0.6534);
  }
  to {
    transform: translate(80.11885vw, 100vh) scale(0.6534);
  }
}
.snow:nth-child(2) {
  opacity: 0.9985;
  transform: translate(49.1259vw, -10px) scale(0.5937);
  animation: fall-2 12s -10s linear infinite;
}
@keyframes fall-2 {
  54.13% {
    transform: translate(49.9815vw, 54.13vh) scale(0.5937);
  }
  to {
    transform: translate(49.5537vw, 100vh) scale(0.5937);
  }
}
.snow:nth-child(3) {
  opacity: 0.0652;
  transform: translate(57.9625vw, -10px) scale(0.068);
  animation: fall-3 12s -27s linear infinite;
}
@keyframes fall-3 {
  62.846% {
    transform: translate(55.5054vw, 62.846vh) scale(0.068);
  }
  to {
    transform: translate(56.73395vw, 100vh) scale(0.068);
  }
}
.snow:nth-child(4) {
  opacity: 0.8526;
  transform: translate(40.3661vw, -10px) scale(0.5797);
  animation: fall-4 27s -28s linear infinite;
}
@keyframes fall-4 {
  51.486% {
    transform: translate(46.365vw, 51.486vh) scale(0.5797);
  }
  to {
    transform: translate(43.36555vw, 100vh) scale(0.5797);
  }
}
.snow:nth-child(5) {
  opacity: 0.4946;
  transform: translate(92.933vw, -10px) scale(0.928);
  animation: fall-5 25s -9s linear infinite;
}
@keyframes fall-5 {
  57.598% {
    transform: translate(100.2193vw, 57.598vh) scale(0.928);
  }
  to {
    transform: translate(96.57615vw, 100vh) scale(0.928);
  }
}
.snow:nth-child(6) {
  opacity: 0.127;
  transform: translate(75.4284vw, -10px) scale(0.3268);
  animation: fall-6 11s -12s linear infinite;
}
@keyframes fall-6 {
  58.487% {
    transform: translate(80.0028vw, 58.487vh) scale(0.3268);
  }
  to {
    transform: translate(77.7156vw, 100vh) scale(0.3268);
  }
}
.snow:nth-child(7) {
  opacity: 0.2756;
  transform: translate(64.5773vw, -10px) scale(0.4662);
  animation: fall-7 21s -23s linear infinite;
}
@keyframes fall-7 {
  66.843% {
    transform: translate(57.4367vw, 66.843vh) scale(0.4662);
  }
  to {
    transform: translate(61.007vw, 100vh) scale(0.4662);
  }
}
.snow:nth-child(8) {
  opacity: 0.3221;
  transform: translate(2.697vw, -10px) scale(0.0876);
  animation: fall-8 10s -18s linear infinite;
}
@keyframes fall-8 {
  38.138% {
    transform: translate(-4.9489vw, 38.138vh) scale(0.0876);
  }
  to {
    transform: translate(-1.12595vw, 100vh) scale(0.0876);
  }
}
.snow:nth-child(9) {
  opacity: 0.6575;
  transform: translate(24.9023vw, -10px) scale(0.2967);
  animation: fall-9 27s -28s linear infinite;
}
@keyframes fall-9 {
  37.046% {
    transform: translate(32.8519vw, 37.046vh) scale(0.2967);
  }
  to {
    transform: translate(28.8771vw, 100vh) scale(0.2967);
  }
}
.snow:nth-child(10) {
  opacity: 0.7175;
  transform: translate(55.454vw, -10px) scale(0.1265);
  animation: fall-10 25s -17s linear infinite;
}
@keyframes fall-10 {
  49.996% {
    transform: translate(50.7793vw, 49.996vh) scale(0.1265);
  }
  to {
    transform: translate(53.11665vw, 100vh) scale(0.1265);
  }
}
.snow:nth-child(11) {
  opacity: 0.7641;
  transform: translate(75.7835vw, -10px) scale(0.1624);
  animation: fall-11 15s -30s linear infinite;
}
@keyframes fall-11 {
  44.399% {
    transform: translate(83.2433vw, 44.399vh) scale(0.1624);
  }
  to {
    transform: translate(79.5134vw, 100vh) scale(0.1624);
  }
}
.snow:nth-child(12) {
  opacity: 0.2565;
  transform: translate(15.4525vw, -10px) scale(0.7074);
  animation: fall-12 17s -16s linear infinite;
}
@keyframes fall-12 {
  78.968% {
    transform: translate(21.2713vw, 78.968vh) scale(0.7074);
  }
  to {
    transform: translate(18.3619vw, 100vh) scale(0.7074);
  }
}
.snow:nth-child(13) {
  opacity: 0.3344;
  transform: translate(70.6669vw, -10px) scale(0.0785);
  animation: fall-13 12s -19s linear infinite;
}
@keyframes fall-13 {
  64.033% {
    transform: translate(65.5737vw, 64.033vh) scale(0.0785);
  }
  to {
    transform: translate(68.1203vw, 100vh) scale(0.0785);
  }
}
.snow:nth-child(14) {
  opacity: 0.672;
  transform: translate(66.4646vw, -10px) scale(0.1221);
  animation: fall-14 28s -10s linear infinite;
}
@keyframes fall-14 {
  65.341% {
    transform: translate(67.4912vw, 65.341vh) scale(0.1221);
  }
  to {
    transform: translate(66.9779vw, 100vh) scale(0.1221);
  }
}
.snow:nth-child(15) {
  opacity: 0.4568;
  transform: translate(45.6514vw, -10px) scale(0.2894);
  animation: fall-15 19s -30s linear infinite;
}
@keyframes fall-15 {
  46.542% {
    transform: translate(51.1341vw, 46.542vh) scale(0.2894);
  }
  to {
    transform: translate(48.39275vw, 100vh) scale(0.2894);
  }
}
.snow:nth-child(16) {
  opacity: 0.5011;
  transform: translate(4.9545vw, -10px) scale(0.6638);
  animation: fall-16 17s -28s linear infinite;
}
@keyframes fall-16 {
  78.233% {
    transform: translate(12.6364vw, 78.233vh) scale(0.6638);
  }
  to {
    transform: translate(8.79545vw, 100vh) scale(0.6638);
  }
}
.snow:nth-child(17) {
  opacity: 0.26;
  transform: translate(85.8213vw, -10px) scale(0.8281);
  animation: fall-17 14s -25s linear infinite;
}
@keyframes fall-17 {
  65.689% {
    transform: translate(94.2227vw, 65.689vh) scale(0.8281);
  }
  to {
    transform: translate(90.022vw, 100vh) scale(0.8281);
  }
}
.snow:nth-child(18) {
  opacity: 0.2566;
  transform: translate(31.0592vw, -10px) scale(0.9161);
  animation: fall-18 24s -21s linear infinite;
}
@keyframes fall-18 {
  57.96% {
    transform: translate(23.4207vw, 57.96vh) scale(0.9161);
  }
  to {
    transform: translate(27.23995vw, 100vh) scale(0.9161);
  }
}
.snow:nth-child(19) {
  opacity: 0.6513;
  transform: translate(31.8406vw, -10px) scale(0.0192);
  animation: fall-19 28s -26s linear infinite;
}
@keyframes fall-19 {
  72.52% {
    transform: translate(30.9075vw, 72.52vh) scale(0.0192);
  }
  to {
    transform: translate(31.37405vw, 100vh) scale(0.0192);
  }
}
.snow:nth-child(20) {
  opacity: 0.9805;
  transform: translate(84.5281vw, -10px) scale(0.9985);
  animation: fall-20 19s -16s linear infinite;
}
@keyframes fall-20 {
  71.984% {
    transform: translate(79.2333vw, 71.984vh) scale(0.9985);
  }
  to {
    transform: translate(81.8807vw, 100vh) scale(0.9985);
  }
}
.snow:nth-child(21) {
  opacity: 0.8671;
  transform: translate(51.9887vw, -10px) scale(0.6572);
  animation: fall-21 21s -30s linear infinite;
}
@keyframes fall-21 {
  41.356% {
    transform: translate(46.1525vw, 41.356vh) scale(0.6572);
  }
  to {
    transform: translate(49.0706vw, 100vh) scale(0.6572);
  }
}
.snow:nth-child(22) {
  opacity: 0.4916;
  transform: translate(29.9649vw, -10px) scale(0.7656);
  animation: fall-22 14s -17s linear infinite;
}
@keyframes fall-22 {
  44.072% {
    transform: translate(38.955vw, 44.072vh) scale(0.7656);
  }
  to {
    transform: translate(34.45995vw, 100vh) scale(0.7656);
  }
}
.snow:nth-child(23) {
  opacity: 0.4527;
  transform: translate(59.5532vw, -10px) scale(0.4447);
  animation: fall-23 18s -16s linear infinite;
}
@keyframes fall-23 {
  42.442% {
    transform: translate(62.3186vw, 42.442vh) scale(0.4447);
  }
  to {
    transform: translate(60.9359vw, 100vh) scale(0.4447);
  }
}
.snow:nth-child(24) {
  opacity: 0.0113;
  transform: translate(80.5025vw, -10px) scale(0.8774);
  animation: fall-24 14s -16s linear infinite;
}
@keyframes fall-24 {
  68.609% {
    transform: translate(87.3187vw, 68.609vh) scale(0.8774);
  }
  to {
    transform: translate(83.9106vw, 100vh) scale(0.8774);
  }
}
.snow:nth-child(25) {
  opacity: 0.4764;
  transform: translate(79.5857vw, -10px) scale(0.605);
  animation: fall-25 27s -11s linear infinite;
}
@keyframes fall-25 {
  56.236% {
    transform: translate(75.7797vw, 56.236vh) scale(0.605);
  }
  to {
    transform: translate(77.6827vw, 100vh) scale(0.605);
  }
}
.snow:nth-child(26) {
  opacity: 0.9406;
  transform: translate(58.7332vw, -10px) scale(0.4071);
  animation: fall-26 28s -12s linear infinite;
}
@keyframes fall-26 {
  37.36% {
    transform: translate(52.4605vw, 37.36vh) scale(0.4071);
  }
  to {
    transform: translate(55.59685vw, 100vh) scale(0.4071);
  }
}
.snow:nth-child(27) {
  opacity: 0.0184;
  transform: translate(34.9892vw, -10px) scale(0.0062);
  animation: fall-27 26s -6s linear infinite;
}
@keyframes fall-27 {
  43.064% {
    transform: translate(26.0983vw, 43.064vh) scale(0.0062);
  }
  to {
    transform: translate(30.54375vw, 100vh) scale(0.0062);
  }
}
.snow:nth-child(28) {
  opacity: 0.7512;
  transform: translate(35.9924vw, -10px) scale(0.9989);
  animation: fall-28 29s -26s linear infinite;
}
@keyframes fall-28 {
  55.626% {
    transform: translate(31.9067vw, 55.626vh) scale(0.9989);
  }
  to {
    transform: translate(33.94955vw, 100vh) scale(0.9989);
  }
}
.snow:nth-child(29) {
  opacity: 0.64;
  transform: translate(73.309vw, -10px) scale(0.8733);
  animation: fall-29 10s -15s linear infinite;
}
@keyframes fall-29 {
  69.803% {
    transform: translate(64.7444vw, 69.803vh) scale(0.8733);
  }
  to {
    transform: translate(69.0267vw, 100vh) scale(0.8733);
  }
}
.snow:nth-child(30) {
  opacity: 0.3641;
  transform: translate(34.3621vw, -10px) scale(0.7483);
  animation: fall-30 30s -18s linear infinite;
}
@keyframes fall-30 {
  48.078% {
    transform: translate(30.7657vw, 48.078vh) scale(0.7483);
  }
  to {
    transform: translate(32.5639vw, 100vh) scale(0.7483);
  }
}
.snow:nth-child(31) {
  opacity: 0.0376;
  transform: translate(23.9554vw, -10px) scale(0.3712);
  animation: fall-31 26s -15s linear infinite;
}
@keyframes fall-31 {
  50.196% {
    transform: translate(23.4942vw, 50.196vh) scale(0.3712);
  }
  to {
    transform: translate(23.7248vw, 100vh) scale(0.3712);
  }
}
.snow:nth-child(32) {
  opacity: 0.685;
  transform: translate(75.7711vw, -10px) scale(0.4099);
  animation: fall-32 23s -11s linear infinite;
}
@keyframes fall-32 {
  71.376% {
    transform: translate(71.1261vw, 71.376vh) scale(0.4099);
  }
  to {
    transform: translate(73.4486vw, 100vh) scale(0.4099);
  }
}
.snow:nth-child(33) {
  opacity: 0.7181;
  transform: translate(0.9689vw, -10px) scale(0.3992);
  animation: fall-33 27s -1s linear infinite;
}
@keyframes fall-33 {
  64.219% {
    transform: translate(5.597vw, 64.219vh) scale(0.3992);
  }
  to {
    transform: translate(3.28295vw, 100vh) scale(0.3992);
  }
}
.snow:nth-child(34) {
  opacity: 0.0583;
  transform: translate(85.5194vw, -10px) scale(0.0845);
  animation: fall-34 23s -14s linear infinite;
}
@keyframes fall-34 {
  43.983% {
    transform: translate(92.2734vw, 43.983vh) scale(0.0845);
  }
  to {
    transform: translate(88.8964vw, 100vh) scale(0.0845);
  }
}
.snow:nth-child(35) {
  opacity: 0.4718;
  transform: translate(44.9791vw, -10px) scale(0.5417);
  animation: fall-35 14s -21s linear infinite;
}
@keyframes fall-35 {
  53.295% {
    transform: translate(53.9653vw, 53.295vh) scale(0.5417);
  }
  to {
    transform: translate(49.4722vw, 100vh) scale(0.5417);
  }
}
.snow:nth-child(36) {
  opacity: 0.7686;
  transform: translate(22.4951vw, -10px) scale(0.9441);
  animation: fall-36 30s -16s linear infinite;
}
@keyframes fall-36 {
  38.093% {
    transform: translate(24.4974vw, 38.093vh) scale(0.9441);
  }
  to {
    transform: translate(23.49625vw, 100vh) scale(0.9441);
  }
}
.snow:nth-child(37) {
  opacity: 0.6536;
  transform: translate(11.9843vw, -10px) scale(0.6792);
  animation: fall-37 14s -4s linear infinite;
}
@keyframes fall-37 {
  58.266% {
    transform: translate(13.8184vw, 58.266vh) scale(0.6792);
  }
  to {
    transform: translate(12.90135vw, 100vh) scale(0.6792);
  }
}
.snow:nth-child(38) {
  opacity: 0.0788;
  transform: translate(10.8244vw, -10px) scale(0.5694);
  animation: fall-38 29s -2s linear infinite;
}
@keyframes fall-38 {
  41.71% {
    transform: translate(5.0634vw, 41.71vh) scale(0.5694);
  }
  to {
    transform: translate(7.9439vw, 100vh) scale(0.5694);
  }
}
.snow:nth-child(39) {
  opacity: 0.0015;
  transform: translate(62.9vw, -10px) scale(0.1479);
  animation: fall-39 10s -26s linear infinite;
}
@keyframes fall-39 {
  68.544% {
    transform: translate(66.6219vw, 68.544vh) scale(0.1479);
  }
  to {
    transform: translate(64.76095vw, 100vh) scale(0.1479);
  }
}
.snow:nth-child(40) {
  opacity: 0.0944;
  transform: translate(41.165vw, -10px) scale(0.995);
  animation: fall-40 19s -9s linear infinite;
}
@keyframes fall-40 {
  33.496% {
    transform: translate(32.6453vw, 33.496vh) scale(0.995);
  }
  to {
    transform: translate(36.90515vw, 100vh) scale(0.995);
  }
}
.snow:nth-child(41) {
  opacity: 0.1053;
  transform: translate(38.6422vw, -10px) scale(0.0561);
  animation: fall-41 10s -29s linear infinite;
}
@keyframes fall-41 {
  77.391% {
    transform: translate(38.1573vw, 77.391vh) scale(0.0561);
  }
  to {
    transform: translate(38.39975vw, 100vh) scale(0.0561);
  }
}
.snow:nth-child(42) {
  opacity: 0.2919;
  transform: translate(7.6756vw, -10px) scale(0.7974);
  animation: fall-42 17s -28s linear infinite;
}
@keyframes fall-42 {
  50.988% {
    transform: translate(9.3587vw, 50.988vh) scale(0.7974);
  }
  to {
    transform: translate(8.51715vw, 100vh) scale(0.7974);
  }
}
.snow:nth-child(43) {
  opacity: 0.9733;
  transform: translate(63.5677vw, -10px) scale(0.1986);
  animation: fall-43 11s -21s linear infinite;
}
@keyframes fall-43 {
  47.807% {
    transform: translate(64.3011vw, 47.807vh) scale(0.1986);
  }
  to {
    transform: translate(63.9344vw, 100vh) scale(0.1986);
  }
}
.snow:nth-child(44) {
  opacity: 0.3612;
  transform: translate(24.3131vw, -10px) scale(0.625);
  animation: fall-44 14s -26s linear infinite;
}
@keyframes fall-44 {
  39.289% {
    transform: translate(32.8348vw, 39.289vh) scale(0.625);
  }
  to {
    transform: translate(28.57395vw, 100vh) scale(0.625);
  }
}
.snow:nth-child(45) {
  opacity: 0.3848;
  transform: translate(97.3103vw, -10px) scale(0.4317);
  animation: fall-45 29s -6s linear infinite;
}
@keyframes fall-45 {
  46.684% {
    transform: translate(95.1418vw, 46.684vh) scale(0.4317);
  }
  to {
    transform: translate(96.22605vw, 100vh) scale(0.4317);
  }
}
.snow:nth-child(46) {
  opacity: 0.6437;
  transform: translate(19.3025vw, -10px) scale(0.0503);
  animation: fall-46 23s -21s linear infinite;
}
@keyframes fall-46 {
  32.562% {
    transform: translate(19.9011vw, 32.562vh) scale(0.0503);
  }
  to {
    transform: translate(19.6018vw, 100vh) scale(0.0503);
  }
}
.snow:nth-child(47) {
  opacity: 0.1739;
  transform: translate(56.8953vw, -10px) scale(0.9033);
  animation: fall-47 29s -25s linear infinite;
}
@keyframes fall-47 {
  32.686% {
    transform: translate(61.0079vw, 32.686vh) scale(0.9033);
  }
  to {
    transform: translate(58.9516vw, 100vh) scale(0.9033);
  }
}
.snow:nth-child(48) {
  opacity: 0.2816;
  transform: translate(19.0786vw, -10px) scale(0.2098);
  animation: fall-48 16s -8s linear infinite;
}
@keyframes fall-48 {
  42.531% {
    transform: translate(28.6616vw, 42.531vh) scale(0.2098);
  }
  to {
    transform: translate(23.8701vw, 100vh) scale(0.2098);
  }
}
.snow:nth-child(49) {
  opacity: 0.4006;
  transform: translate(48.1791vw, -10px) scale(0.9775);
  animation: fall-49 22s -7s linear infinite;
}
@keyframes fall-49 {
  38.68% {
    transform: translate(54.5283vw, 38.68vh) scale(0.9775);
  }
  to {
    transform: translate(51.3537vw, 100vh) scale(0.9775);
  }
}
.snow:nth-child(50) {
  opacity: 0.2562;
  transform: translate(10.7258vw, -10px) scale(0.4862);
  animation: fall-50 27s -22s linear infinite;
}
@keyframes fall-50 {
  67.63% {
    transform: translate(8.5786vw, 67.63vh) scale(0.4862);
  }
  to {
    transform: translate(9.6522vw, 100vh) scale(0.4862);
  }
}
.snow:nth-child(51) {
  opacity: 0.0204;
  transform: translate(91.0254vw, -10px) scale(0.1794);
  animation: fall-51 28s -11s linear infinite;
}
@keyframes fall-51 {
  60.786% {
    transform: translate(85.116vw, 60.786vh) scale(0.1794);
  }
  to {
    transform: translate(88.0707vw, 100vh) scale(0.1794);
  }
}
.snow:nth-child(52) {
  opacity: 0.3455;
  transform: translate(28.3157vw, -10px) scale(0.3855);
  animation: fall-52 27s -12s linear infinite;
}
@keyframes fall-52 {
  60.638% {
    transform: translate(27.4868vw, 60.638vh) scale(0.3855);
  }
  to {
    transform: translate(27.90125vw, 100vh) scale(0.3855);
  }
}
.snow:nth-child(53) {
  opacity: 0.2574;
  transform: translate(19.7284vw, -10px) scale(0.9011);
  animation: fall-53 10s -13s linear infinite;
}
@keyframes fall-53 {
  75.161% {
    transform: translate(25.9222vw, 75.161vh) scale(0.9011);
  }
  to {
    transform: translate(22.8253vw, 100vh) scale(0.9011);
  }
}
.snow:nth-child(54) {
  opacity: 0.0663;
  transform: translate(0.0494vw, -10px) scale(0.8012);
  animation: fall-54 24s -18s linear infinite;
}
@keyframes fall-54 {
  34.011% {
    transform: translate(1.9454vw, 34.011vh) scale(0.8012);
  }
  to {
    transform: translate(0.9974vw, 100vh) scale(0.8012);
  }
}
.snow:nth-child(55) {
  opacity: 0.4821;
  transform: translate(65.7753vw, -10px) scale(0.8186);
  animation: fall-55 28s -7s linear infinite;
}
@keyframes fall-55 {
  46.206% {
    transform: translate(60.0065vw, 46.206vh) scale(0.8186);
  }
  to {
    transform: translate(62.8909vw, 100vh) scale(0.8186);
  }
}
.snow:nth-child(56) {
  opacity: 0.7675;
  transform: translate(12.0047vw, -10px) scale(0.001);
  animation: fall-56 27s -12s linear infinite;
}
@keyframes fall-56 {
  69.085% {
    transform: translate(11.8336vw, 69.085vh) scale(0.001);
  }
  to {
    transform: translate(11.91915vw, 100vh) scale(0.001);
  }
}
.snow:nth-child(57) {
  opacity: 0.9545;
  transform: translate(5.7066vw, -10px) scale(0.9879);
  animation: fall-57 28s -5s linear infinite;
}
@keyframes fall-57 {
  54.475% {
    transform: translate(8.1983vw, 54.475vh) scale(0.9879);
  }
  to {
    transform: translate(6.95245vw, 100vh) scale(0.9879);
  }
}
.snow:nth-child(58) {
  opacity: 0.1444;
  transform: translate(3.4898vw, -10px) scale(0.017);
  animation: fall-58 13s -28s linear infinite;
}
@keyframes fall-58 {
  69.782% {
    transform: translate(-3.0728vw, 69.782vh) scale(0.017);
  }
  to {
    transform: translate(0.2085vw, 100vh) scale(0.017);
  }
}
.snow:nth-child(59) {
  opacity: 0.6455;
  transform: translate(25.2819vw, -10px) scale(0.4328);
  animation: fall-59 29s -14s linear infinite;
}
@keyframes fall-59 {
  70.792% {
    transform: translate(20.441vw, 70.792vh) scale(0.4328);
  }
  to {
    transform: translate(22.86145vw, 100vh) scale(0.4328);
  }
}
.snow:nth-child(60) {
  opacity: 0.2747;
  transform: translate(12.1157vw, -10px) scale(0.596);
  animation: fall-60 17s -18s linear infinite;
}
@keyframes fall-60 {
  64.594% {
    transform: translate(6.4094vw, 64.594vh) scale(0.596);
  }
  to {
    transform: translate(9.26255vw, 100vh) scale(0.596);
  }
}
.snow:nth-child(61) {
  opacity: 0.3989;
  transform: translate(80.873vw, -10px) scale(0.2375);
  animation: fall-61 21s -21s linear infinite;
}
@keyframes fall-61 {
  63.5% {
    transform: translate(72.8281vw, 63.5vh) scale(0.2375);
  }
  to {
    transform: translate(76.85055vw, 100vh) scale(0.2375);
  }
}
.snow:nth-child(62) {
  opacity: 0.3134;
  transform: translate(49.3248vw, -10px) scale(0.2404);
  animation: fall-62 23s -10s linear infinite;
}
@keyframes fall-62 {
  61.35% {
    transform: translate(43.3136vw, 61.35vh) scale(0.2404);
  }
  to {
    transform: translate(46.3192vw, 100vh) scale(0.2404);
  }
}
.snow:nth-child(63) {
  opacity: 0.8009;
  transform: translate(21.7831vw, -10px) scale(0.5698);
  animation: fall-63 18s -2s linear infinite;
}
@keyframes fall-63 {
  50.055% {
    transform: translate(26.7999vw, 50.055vh) scale(0.5698);
  }
  to {
    transform: translate(24.2915vw, 100vh) scale(0.5698);
  }
}
.snow:nth-child(64) {
  opacity: 0.1777;
  transform: translate(48.1819vw, -10px) scale(0.5052);
  animation: fall-64 16s -23s linear infinite;
}
@keyframes fall-64 {
  39.932% {
    transform: translate(47.7536vw, 39.932vh) scale(0.5052);
  }
  to {
    transform: translate(47.96775vw, 100vh) scale(0.5052);
  }
}
.snow:nth-child(65) {
  opacity: 0.8111;
  transform: translate(3.7624vw, -10px) scale(0.4891);
  animation: fall-65 22s -23s linear infinite;
}
@keyframes fall-65 {
  71.055% {
    transform: translate(-6.0943vw, 71.055vh) scale(0.4891);
  }
  to {
    transform: translate(-1.16595vw, 100vh) scale(0.4891);
  }
}
.snow:nth-child(66) {
  opacity: 0.1115;
  transform: translate(37.6066vw, -10px) scale(0.0796);
  animation: fall-66 14s -1s linear infinite;
}
@keyframes fall-66 {
  51.785% {
    transform: translate(40.2317vw, 51.785vh) scale(0.0796);
  }
  to {
    transform: translate(38.91915vw, 100vh) scale(0.0796);
  }
}
.snow:nth-child(67) {
  opacity: 0.4383;
  transform: translate(45.8664vw, -10px) scale(0.3722);
  animation: fall-67 19s -2s linear infinite;
}
@keyframes fall-67 {
  66.452% {
    transform: translate(52.6705vw, 66.452vh) scale(0.3722);
  }
  to {
    transform: translate(49.26845vw, 100vh) scale(0.3722);
  }
}
.snow:nth-child(68) {
  opacity: 0.1008;
  transform: translate(99.7628vw, -10px) scale(0.9197);
  animation: fall-68 30s -10s linear infinite;
}
@keyframes fall-68 {
  66.447% {
    transform: translate(102.1961vw, 66.447vh) scale(0.9197);
  }
  to {
    transform: translate(100.97945vw, 100vh) scale(0.9197);
  }
}
.snow:nth-child(69) {
  opacity: 0.8009;
  transform: translate(84.8905vw, -10px) scale(0.6798);
  animation: fall-69 29s -27s linear infinite;
}
@keyframes fall-69 {
  33.655% {
    transform: translate(79.6479vw, 33.655vh) scale(0.6798);
  }
  to {
    transform: translate(82.2692vw, 100vh) scale(0.6798);
  }
}
.snow:nth-child(70) {
  opacity: 0.6669;
  transform: translate(26.1257vw, -10px) scale(0.6474);
  animation: fall-70 30s -19s linear infinite;
}
@keyframes fall-70 {
  67.921% {
    transform: translate(27.9715vw, 67.921vh) scale(0.6474);
  }
  to {
    transform: translate(27.0486vw, 100vh) scale(0.6474);
  }
}
.snow:nth-child(71) {
  opacity: 0.9658;
  transform: translate(53.5124vw, -10px) scale(0.9248);
  animation: fall-71 22s -30s linear infinite;
}
@keyframes fall-71 {
  48.091% {
    transform: translate(52.2827vw, 48.091vh) scale(0.9248);
  }
  to {
    transform: translate(52.89755vw, 100vh) scale(0.9248);
  }
}
.snow:nth-child(72) {
  opacity: 0.8475;
  transform: translate(9.843vw, -10px) scale(0.8535);
  animation: fall-72 29s -24s linear infinite;
}
@keyframes fall-72 {
  46.968% {
    transform: translate(18.5361vw, 46.968vh) scale(0.8535);
  }
  to {
    transform: translate(14.18955vw, 100vh) scale(0.8535);
  }
}
.snow:nth-child(73) {
  opacity: 0.1307;
  transform: translate(84.1945vw, -10px) scale(0.7202);
  animation: fall-73 21s -11s linear infinite;
}
@keyframes fall-73 {
  32.301% {
    transform: translate(76.4956vw, 32.301vh) scale(0.7202);
  }
  to {
    transform: translate(80.34505vw, 100vh) scale(0.7202);
  }
}
.snow:nth-child(74) {
  opacity: 0.7026;
  transform: translate(2.4749vw, -10px) scale(0.3124);
  animation: fall-74 15s -10s linear infinite;
}
@keyframes fall-74 {
  47.318% {
    transform: translate(-5.9807vw, 47.318vh) scale(0.3124);
  }
  to {
    transform: translate(-1.7529vw, 100vh) scale(0.3124);
  }
}
.snow:nth-child(75) {
  opacity: 0.507;
  transform: translate(32.347vw, -10px) scale(0.7049);
  animation: fall-75 21s -25s linear infinite;
}
@keyframes fall-75 {
  79.661% {
    transform: translate(32.1608vw, 79.661vh) scale(0.7049);
  }
  to {
    transform: translate(32.2539vw, 100vh) scale(0.7049);
  }
}
.snow:nth-child(76) {
  opacity: 0.1287;
  transform: translate(1.549vw, -10px) scale(0.2749);
  animation: fall-76 30s -17s linear infinite;
}
@keyframes fall-76 {
  39.79% {
    transform: translate(4.6625vw, 39.79vh) scale(0.2749);
  }
  to {
    transform: translate(3.10575vw, 100vh) scale(0.2749);
  }
}
.snow:nth-child(77) {
  opacity: 0.8646;
  transform: translate(59.1494vw, -10px) scale(0.9882);
  animation: fall-77 28s -5s linear infinite;
}
@keyframes fall-77 {
  48.55% {
    transform: translate(63.4431vw, 48.55vh) scale(0.9882);
  }
  to {
    transform: translate(61.29625vw, 100vh) scale(0.9882);
  }
}
.snow:nth-child(78) {
  opacity: 0.3385;
  transform: translate(86.6532vw, -10px) scale(0.5639);
  animation: fall-78 11s -9s linear infinite;
}
@keyframes fall-78 {
  39.381% {
    transform: translate(93.4938vw, 39.381vh) scale(0.5639);
  }
  to {
    transform: translate(90.0735vw, 100vh) scale(0.5639);
  }
}
.snow:nth-child(79) {
  opacity: 0.381;
  transform: translate(92.9466vw, -10px) scale(0.5086);
  animation: fall-79 11s -20s linear infinite;
}
@keyframes fall-79 {
  44.697% {
    transform: translate(94.2833vw, 44.697vh) scale(0.5086);
  }
  to {
    transform: translate(93.61495vw, 100vh) scale(0.5086);
  }
}
.snow:nth-child(80) {
  opacity: 0.2834;
  transform: translate(49.7975vw, -10px) scale(0.6594);
  animation: fall-80 15s -15s linear infinite;
}
@keyframes fall-80 {
  31.569% {
    transform: translate(55.0005vw, 31.569vh) scale(0.6594);
  }
  to {
    transform: translate(52.399vw, 100vh) scale(0.6594);
  }
}
.snow:nth-child(81) {
  opacity: 0.0206;
  transform: translate(77.4936vw, -10px) scale(0.8372);
  animation: fall-81 11s -19s linear infinite;
}
@keyframes fall-81 {
  63.354% {
    transform: translate(70.3902vw, 63.354vh) scale(0.8372);
  }
  to {
    transform: translate(73.9419vw, 100vh) scale(0.8372);
  }
}
.snow:nth-child(82) {
  opacity: 0.9071;
  transform: translate(69.7072vw, -10px) scale(0.3903);
  animation: fall-82 20s -2s linear infinite;
}
@keyframes fall-82 {
  78.557% {
    transform: translate(73.4535vw, 78.557vh) scale(0.3903);
  }
  to {
    transform: translate(71.58035vw, 100vh) scale(0.3903);
  }
}
.snow:nth-child(83) {
  opacity: 0.7924;
  transform: translate(82.4353vw, -10px) scale(0.47);
  animation: fall-83 21s -15s linear infinite;
}
@keyframes fall-83 {
  74.266% {
    transform: translate(74.5592vw, 74.266vh) scale(0.47);
  }
  to {
    transform: translate(78.49725vw, 100vh) scale(0.47);
  }
}
.snow:nth-child(84) {
  opacity: 0.7361;
  transform: translate(0.7837vw, -10px) scale(0.8801);
  animation: fall-84 12s -4s linear infinite;
}
@keyframes fall-84 {
  51.974% {
    transform: translate(-3.3811vw, 51.974vh) scale(0.8801);
  }
  to {
    transform: translate(-1.2987vw, 100vh) scale(0.8801);
  }
}
.snow:nth-child(85) {
  opacity: 0.9123;
  transform: translate(72.3328vw, -10px) scale(0.5424);
  animation: fall-85 30s -11s linear infinite;
}
@keyframes fall-85 {
  41.686% {
    transform: translate(77.4781vw, 41.686vh) scale(0.5424);
  }
  to {
    transform: translate(74.90545vw, 100vh) scale(0.5424);
  }
}
.snow:nth-child(86) {
  opacity: 0.444;
  transform: translate(46.6244vw, -10px) scale(0.565);
  animation: fall-86 23s -18s linear infinite;
}
@keyframes fall-86 {
  37.262% {
    transform: translate(37.8996vw, 37.262vh) scale(0.565);
  }
  to {
    transform: translate(42.262vw, 100vh) scale(0.565);
  }
}
.snow:nth-child(87) {
  opacity: 0.1679;
  transform: translate(11.8821vw, -10px) scale(0.4951);
  animation: fall-87 22s -26s linear infinite;
}
@keyframes fall-87 {
  57.032% {
    transform: translate(7.4559vw, 57.032vh) scale(0.4951);
  }
  to {
    transform: translate(9.669vw, 100vh) scale(0.4951);
  }
}
.snow:nth-child(88) {
  opacity: 0.9066;
  transform: translate(95.5041vw, -10px) scale(0.1369);
  animation: fall-88 13s -8s linear infinite;
}
@keyframes fall-88 {
  73.373% {
    transform: translate(89.5904vw, 73.373vh) scale(0.1369);
  }
  to {
    transform: translate(92.54725vw, 100vh) scale(0.1369);
  }
}
.snow:nth-child(89) {
  opacity: 0.7233;
  transform: translate(30.5471vw, -10px) scale(0.512);
  animation: fall-89 24s -4s linear infinite;
}
@keyframes fall-89 {
  79.392% {
    transform: translate(29.7806vw, 79.392vh) scale(0.512);
  }
  to {
    transform: translate(30.16385vw, 100vh) scale(0.512);
  }
}
.snow:nth-child(90) {
  opacity: 0.2961;
  transform: translate(85.4858vw, -10px) scale(0.8083);
  animation: fall-90 24s -14s linear infinite;
}
@keyframes fall-90 {
  37.721% {
    transform: translate(78.7841vw, 37.721vh) scale(0.8083);
  }
  to {
    transform: translate(82.13495vw, 100vh) scale(0.8083);
  }
}
.snow:nth-child(91) {
  opacity: 0.9697;
  transform: translate(17.6658vw, -10px) scale(0.9708);
  animation: fall-91 30s -12s linear infinite;
}
@keyframes fall-91 {
  56.216% {
    transform: translate(24.4451vw, 56.216vh) scale(0.9708);
  }
  to {
    transform: translate(21.05545vw, 100vh) scale(0.9708);
  }
}
.snow:nth-child(92) {
  opacity: 0.7274;
  transform: translate(12.6583vw, -10px) scale(0.0573);
  animation: fall-92 10s -10s linear infinite;
}
@keyframes fall-92 {
  69.867% {
    transform: translate(15.4734vw, 69.867vh) scale(0.0573);
  }
  to {
    transform: translate(14.06585vw, 100vh) scale(0.0573);
  }
}
.snow:nth-child(93) {
  opacity: 0.5674;
  transform: translate(65.1589vw, -10px) scale(0.6375);
  animation: fall-93 30s -24s linear infinite;
}
@keyframes fall-93 {
  55.998% {
    transform: translate(72.7292vw, 55.998vh) scale(0.6375);
  }
  to {
    transform: translate(68.94405vw, 100vh) scale(0.6375);
  }
}
.snow:nth-child(94) {
  opacity: 0.4146;
  transform: translate(64.8034vw, -10px) scale(0.9158);
  animation: fall-94 24s -14s linear infinite;
}
@keyframes fall-94 {
  54.284% {
    transform: translate(58.4402vw, 54.284vh) scale(0.9158);
  }
  to {
    transform: translate(61.6218vw, 100vh) scale(0.9158);
  }
}
.snow:nth-child(95) {
  opacity: 0.037;
  transform: translate(14.2675vw, -10px) scale(0.113);
  animation: fall-95 12s -7s linear infinite;
}
@keyframes fall-95 {
  49.796% {
    transform: translate(23.4042vw, 49.796vh) scale(0.113);
  }
  to {
    transform: translate(18.83585vw, 100vh) scale(0.113);
  }
}
.snow:nth-child(96) {
  opacity: 0.1639;
  transform: translate(42.1891vw, -10px) scale(0.7279);
  animation: fall-96 26s -17s linear infinite;
}
@keyframes fall-96 {
  30.305% {
    transform: translate(45.0991vw, 30.305vh) scale(0.7279);
  }
  to {
    transform: translate(43.6441vw, 100vh) scale(0.7279);
  }
}
.snow:nth-child(97) {
  opacity: 0.059;
  transform: translate(52.5386vw, -10px) scale(0.8425);
  animation: fall-97 17s -3s linear infinite;
}
@keyframes fall-97 {
  51.481% {
    transform: translate(54.5763vw, 51.481vh) scale(0.8425);
  }
  to {
    transform: translate(53.55745vw, 100vh) scale(0.8425);
  }
}
.snow:nth-child(98) {
  opacity: 0.0626;
  transform: translate(91.3681vw, -10px) scale(0.7086);
  animation: fall-98 27s -19s linear infinite;
}
@keyframes fall-98 {
  64.578% {
    transform: translate(85.4788vw, 64.578vh) scale(0.7086);
  }
  to {
    transform: translate(88.42345vw, 100vh) scale(0.7086);
  }
}
.snow:nth-child(99) {
  opacity: 0.8872;
  transform: translate(41.4003vw, -10px) scale(0.7575);
  animation: fall-99 10s -5s linear infinite;
}
@keyframes fall-99 {
  66.73% {
    transform: translate(45.7996vw, 66.73vh) scale(0.7575);
  }
  to {
    transform: translate(43.59995vw, 100vh) scale(0.7575);
  }
}
.snow:nth-child(100) {
  opacity: 0.0567;
  transform: translate(55.9533vw, -10px) scale(0.9046);
  animation: fall-100 25s -16s linear infinite;
}
@keyframes fall-100 {
  72.553% {
    transform: translate(46.7603vw, 72.553vh) scale(0.9046);
  }
  to {
    transform: translate(51.3568vw, 100vh) scale(0.9046);
  }
}
.snow:nth-child(101) {
  opacity: 0.5925;
  transform: translate(77.594vw, -10px) scale(0.6328);
  animation: fall-101 26s -23s linear infinite;
}
@keyframes fall-101 {
  74.166% {
    transform: translate(81.3264vw, 74.166vh) scale(0.6328);
  }
  to {
    transform: translate(79.4602vw, 100vh) scale(0.6328);
  }
}
.snow:nth-child(102) {
  opacity: 0.7931;
  transform: translate(62.3336vw, -10px) scale(0.5634);
  animation: fall-102 24s -23s linear infinite;
}
@keyframes fall-102 {
  45.584% {
    transform: translate(55.5054vw, 45.584vh) scale(0.5634);
  }
  to {
    transform: translate(58.9195vw, 100vh) scale(0.5634);
  }
}
.snow:nth-child(103) {
  opacity: 0.8049;
  transform: translate(94.203vw, -10px) scale(0.0132);
  animation: fall-103 12s -28s linear infinite;
}
@keyframes fall-103 {
  53.9% {
    transform: translate(96.3996vw, 53.9vh) scale(0.0132);
  }
  to {
    transform: translate(95.3013vw, 100vh) scale(0.0132);
  }
}
.snow:nth-child(104) {
  opacity: 0.8794;
  transform: translate(22.3862vw, -10px) scale(0.2387);
  animation: fall-104 12s -17s linear infinite;
}
@keyframes fall-104 {
  61.583% {
    transform: translate(16.2344vw, 61.583vh) scale(0.2387);
  }
  to {
    transform: translate(19.3103vw, 100vh) scale(0.2387);
  }
}
.snow:nth-child(105) {
  opacity: 0.5668;
  transform: translate(85.5727vw, -10px) scale(0.3749);
  animation: fall-105 20s -14s linear infinite;
}
@keyframes fall-105 {
  58.418% {
    transform: translate(79.3504vw, 58.418vh) scale(0.3749);
  }
  to {
    transform: translate(82.46155vw, 100vh) scale(0.3749);
  }
}
.snow:nth-child(106) {
  opacity: 0.0963;
  transform: translate(78.0468vw, -10px) scale(0.7228);
  animation: fall-106 25s -30s linear infinite;
}
@keyframes fall-106 {
  68.723% {
    transform: translate(68.3257vw, 68.723vh) scale(0.7228);
  }
  to {
    transform: translate(73.18625vw, 100vh) scale(0.7228);
  }
}
.snow:nth-child(107) {
  opacity: 0.7987;
  transform: translate(85.452vw, -10px) scale(0.0927);
  animation: fall-107 13s -3s linear infinite;
}
@keyframes fall-107 {
  58.39% {
    transform: translate(84.9007vw, 58.39vh) scale(0.0927);
  }
  to {
    transform: translate(85.17635vw, 100vh) scale(0.0927);
  }
}
.snow:nth-child(108) {
  opacity: 0.1844;
  transform: translate(60.0821vw, -10px) scale(0.9776);
  animation: fall-108 19s -7s linear infinite;
}
@keyframes fall-108 {
  73.862% {
    transform: translate(54.439vw, 73.862vh) scale(0.9776);
  }
  to {
    transform: translate(57.26055vw, 100vh) scale(0.9776);
  }
}
.snow:nth-child(109) {
  opacity: 0.2143;
  transform: translate(94.6122vw, -10px) scale(0.9472);
  animation: fall-109 18s -14s linear infinite;
}
@keyframes fall-109 {
  32.475% {
    transform: translate(89.5544vw, 32.475vh) scale(0.9472);
  }
  to {
    transform: translate(92.0833vw, 100vh) scale(0.9472);
  }
}
.snow:nth-child(110) {
  opacity: 0.8617;
  transform: translate(69.9558vw, -10px) scale(0.8447);
  animation: fall-110 27s -9s linear infinite;
}
@keyframes fall-110 {
  55.93% {
    transform: translate(76.4781vw, 55.93vh) scale(0.8447);
  }
  to {
    transform: translate(73.21695vw, 100vh) scale(0.8447);
  }
}
.snow:nth-child(111) {
  opacity: 0.9828;
  transform: translate(27.778vw, -10px) scale(0.3044);
  animation: fall-111 29s -1s linear infinite;
}
@keyframes fall-111 {
  70.713% {
    transform: translate(25.0855vw, 70.713vh) scale(0.3044);
  }
  to {
    transform: translate(26.43175vw, 100vh) scale(0.3044);
  }
}
.snow:nth-child(112) {
  opacity: 0.9569;
  transform: translate(80.9886vw, -10px) scale(0.7345);
  animation: fall-112 16s -20s linear infinite;
}
@keyframes fall-112 {
  73.817% {
    transform: translate(71.5802vw, 73.817vh) scale(0.7345);
  }
  to {
    transform: translate(76.2844vw, 100vh) scale(0.7345);
  }
}
.snow:nth-child(113) {
  opacity: 0.3489;
  transform: translate(68.6288vw, -10px) scale(0.5687);
  animation: fall-113 13s -28s linear infinite;
}
@keyframes fall-113 {
  64.403% {
    transform: translate(72.3302vw, 64.403vh) scale(0.5687);
  }
  to {
    transform: translate(70.4795vw, 100vh) scale(0.5687);
  }
}
.snow:nth-child(114) {
  opacity: 0.3505;
  transform: translate(77.8055vw, -10px) scale(0.2575);
  animation: fall-114 20s -2s linear infinite;
}
@keyframes fall-114 {
  72.828% {
    transform: translate(74.4655vw, 72.828vh) scale(0.2575);
  }
  to {
    transform: translate(76.1355vw, 100vh) scale(0.2575);
  }
}
.snow:nth-child(115) {
  opacity: 0.6924;
  transform: translate(77.3223vw, -10px) scale(0.9391);
  animation: fall-115 28s -21s linear infinite;
}
@keyframes fall-115 {
  44.191% {
    transform: translate(70.5593vw, 44.191vh) scale(0.9391);
  }
  to {
    transform: translate(73.9408vw, 100vh) scale(0.9391);
  }
}
.snow:nth-child(116) {
  opacity: 0.4986;
  transform: translate(78.2273vw, -10px) scale(0.5419);
  animation: fall-116 14s -8s linear infinite;
}
@keyframes fall-116 {
  41.361% {
    transform: translate(75.0502vw, 41.361vh) scale(0.5419);
  }
  to {
    transform: translate(76.63875vw, 100vh) scale(0.5419);
  }
}
.snow:nth-child(117) {
  opacity: 0.225;
  transform: translate(83.9941vw, -10px) scale(0.5851);
  animation: fall-117 26s -13s linear infinite;
}
@keyframes fall-117 {
  74.785% {
    transform: translate(83.8704vw, 74.785vh) scale(0.5851);
  }
  to {
    transform: translate(83.93225vw, 100vh) scale(0.5851);
  }
}
.snow:nth-child(118) {
  opacity: 0.7029;
  transform: translate(25.9938vw, -10px) scale(0.111);
  animation: fall-118 20s -19s linear infinite;
}
@keyframes fall-118 {
  38.08% {
    transform: translate(23.3142vw, 38.08vh) scale(0.111);
  }
  to {
    transform: translate(24.654vw, 100vh) scale(0.111);
  }
}
.snow:nth-child(119) {
  opacity: 0.6962;
  transform: translate(92.2643vw, -10px) scale(0.9836);
  animation: fall-119 10s -4s linear infinite;
}
@keyframes fall-119 {
  44.485% {
    transform: translate(97.4225vw, 44.485vh) scale(0.9836);
  }
  to {
    transform: translate(94.8434vw, 100vh) scale(0.9836);
  }
}
.snow:nth-child(120) {
  opacity: 0.8952;
  transform: translate(71.8371vw, -10px) scale(0.2241);
  animation: fall-120 29s -3s linear infinite;
}
@keyframes fall-120 {
  57.855% {
    transform: translate(80.1723vw, 57.855vh) scale(0.2241);
  }
  to {
    transform: translate(76.0047vw, 100vh) scale(0.2241);
  }
}
.snow:nth-child(121) {
  opacity: 0.5655;
  transform: translate(53.7056vw, -10px) scale(0.2448);
  animation: fall-121 20s -14s linear infinite;
}
@keyframes fall-121 {
  76.78% {
    transform: translate(55.3486vw, 76.78vh) scale(0.2448);
  }
  to {
    transform: translate(54.5271vw, 100vh) scale(0.2448);
  }
}
.snow:nth-child(122) {
  opacity: 0.3693;
  transform: translate(31.0767vw, -10px) scale(0.5773);
  animation: fall-122 22s -23s linear infinite;
}
@keyframes fall-122 {
  46.371% {
    transform: translate(21.4421vw, 46.371vh) scale(0.5773);
  }
  to {
    transform: translate(26.2594vw, 100vh) scale(0.5773);
  }
}
.snow:nth-child(123) {
  opacity: 0.419;
  transform: translate(81.3858vw, -10px) scale(0.1799);
  animation: fall-123 13s -26s linear infinite;
}
@keyframes fall-123 {
  77.202% {
    transform: translate(87.7475vw, 77.202vh) scale(0.1799);
  }
  to {
    transform: translate(84.56665vw, 100vh) scale(0.1799);
  }
}
.snow:nth-child(124) {
  opacity: 0.0169;
  transform: translate(64.8303vw, -10px) scale(0.396);
  animation: fall-124 16s -2s linear infinite;
}
@keyframes fall-124 {
  60.209% {
    transform: translate(74.1657vw, 60.209vh) scale(0.396);
  }
  to {
    transform: translate(69.498vw, 100vh) scale(0.396);
  }
}
.snow:nth-child(125) {
  opacity: 0.392;
  transform: translate(3.5865vw, -10px) scale(0.1266);
  animation: fall-125 17s -20s linear infinite;
}
@keyframes fall-125 {
  78.417% {
    transform: translate(6.3393vw, 78.417vh) scale(0.1266);
  }
  to {
    transform: translate(4.9629vw, 100vh) scale(0.1266);
  }
}
.snow:nth-child(126) {
  opacity: 0.2466;
  transform: translate(83.987vw, -10px) scale(0.4235);
  animation: fall-126 29s -27s linear infinite;
}
@keyframes fall-126 {
  43.456% {
    transform: translate(87.333vw, 43.456vh) scale(0.4235);
  }
  to {
    transform: translate(85.66vw, 100vh) scale(0.4235);
  }
}
.snow:nth-child(127) {
  opacity: 0.6596;
  transform: translate(24.9958vw, -10px) scale(0.7479);
  animation: fall-127 29s -3s linear infinite;
}
@keyframes fall-127 {
  72.342% {
    transform: translate(33.7389vw, 72.342vh) scale(0.7479);
  }
  to {
    transform: translate(29.36735vw, 100vh) scale(0.7479);
  }
}
.snow:nth-child(128) {
  opacity: 0.6414;
  transform: translate(77.4768vw, -10px) scale(0.4256);
  animation: fall-128 24s -18s linear infinite;
}
@keyframes fall-128 {
  77.448% {
    transform: translate(84.013vw, 77.448vh) scale(0.4256);
  }
  to {
    transform: translate(80.7449vw, 100vh) scale(0.4256);
  }
}
.snow:nth-child(129) {
  opacity: 0.2987;
  transform: translate(58.3698vw, -10px) scale(0.0218);
  animation: fall-129 10s -16s linear infinite;
}
@keyframes fall-129 {
  55.736% {
    transform: translate(58.0797vw, 55.736vh) scale(0.0218);
  }
  to {
    transform: translate(58.22475vw, 100vh) scale(0.0218);
  }
}
.snow:nth-child(130) {
  opacity: 0.0802;
  transform: translate(96.8737vw, -10px) scale(0.2781);
  animation: fall-130 29s -18s linear infinite;
}
@keyframes fall-130 {
  77.553% {
    transform: translate(87.2622vw, 77.553vh) scale(0.2781);
  }
  to {
    transform: translate(92.06795vw, 100vh) scale(0.2781);
  }
}
.snow:nth-child(131) {
  opacity: 0.6949;
  transform: translate(73.1899vw, -10px) scale(0.4276);
  animation: fall-131 28s -6s linear infinite;
}
@keyframes fall-131 {
  34.594% {
    transform: translate(77.7495vw, 34.594vh) scale(0.4276);
  }
  to {
    transform: translate(75.4697vw, 100vh) scale(0.4276);
  }
}
.snow:nth-child(132) {
  opacity: 0.9301;
  transform: translate(43.2764vw, -10px) scale(0.1332);
  animation: fall-132 25s -22s linear infinite;
}
@keyframes fall-132 {
  77.204% {
    transform: translate(46.5124vw, 77.204vh) scale(0.1332);
  }
  to {
    transform: translate(44.8944vw, 100vh) scale(0.1332);
  }
}
.snow:nth-child(133) {
  opacity: 0.6206;
  transform: translate(0.409vw, -10px) scale(0.3706);
  animation: fall-133 16s -5s linear infinite;
}
@keyframes fall-133 {
  43.546% {
    transform: translate(5.7392vw, 43.546vh) scale(0.3706);
  }
  to {
    transform: translate(3.0741vw, 100vh) scale(0.3706);
  }
}
.snow:nth-child(134) {
  opacity: 0.7787;
  transform: translate(41.0863vw, -10px) scale(0.9054);
  animation: fall-134 23s -7s linear infinite;
}
@keyframes fall-134 {
  42.286% {
    transform: translate(41.5814vw, 42.286vh) scale(0.9054);
  }
  to {
    transform: translate(41.33385vw, 100vh) scale(0.9054);
  }
}
.snow:nth-child(135) {
  opacity: 0.188;
  transform: translate(50.4249vw, -10px) scale(0.306);
  animation: fall-135 25s -30s linear infinite;
}
@keyframes fall-135 {
  63.648% {
    transform: translate(42.5642vw, 63.648vh) scale(0.306);
  }
  to {
    transform: translate(46.49455vw, 100vh) scale(0.306);
  }
}
.snow:nth-child(136) {
  opacity: 0.2069;
  transform: translate(74.8138vw, -10px) scale(0.7996);
  animation: fall-136 28s -25s linear infinite;
}
@keyframes fall-136 {
  40.512% {
    transform: translate(67.1915vw, 40.512vh) scale(0.7996);
  }
  to {
    transform: translate(71.00265vw, 100vh) scale(0.7996);
  }
}
.snow:nth-child(137) {
  opacity: 0.0682;
  transform: translate(93.3201vw, -10px) scale(0.4574);
  animation: fall-137 24s -9s linear infinite;
}
@keyframes fall-137 {
  76.927% {
    transform: translate(89.1447vw, 76.927vh) scale(0.4574);
  }
  to {
    transform: translate(91.2324vw, 100vh) scale(0.4574);
  }
}
.snow:nth-child(138) {
  opacity: 0.8532;
  transform: translate(28.988vw, -10px) scale(0.8879);
  animation: fall-138 25s -4s linear infinite;
}
@keyframes fall-138 {
  64.01% {
    transform: translate(33.2661vw, 64.01vh) scale(0.8879);
  }
  to {
    transform: translate(31.12705vw, 100vh) scale(0.8879);
  }
}
.snow:nth-child(139) {
  opacity: 0.7661;
  transform: translate(76.425vw, -10px) scale(0.4021);
  animation: fall-139 12s -18s linear infinite;
}
@keyframes fall-139 {
  30.568% {
    transform: translate(79.9482vw, 30.568vh) scale(0.4021);
  }
  to {
    transform: translate(78.1866vw, 100vh) scale(0.4021);
  }
}
.snow:nth-child(140) {
  opacity: 0.6649;
  transform: translate(40.9616vw, -10px) scale(0.9122);
  animation: fall-140 25s -7s linear infinite;
}
@keyframes fall-140 {
  48.396% {
    transform: translate(40.7291vw, 48.396vh) scale(0.9122);
  }
  to {
    transform: translate(40.84535vw, 100vh) scale(0.9122);
  }
}
.snow:nth-child(141) {
  opacity: 0.0448;
  transform: translate(62.3797vw, -10px) scale(0.3274);
  animation: fall-141 11s -30s linear infinite;
}
@keyframes fall-141 {
  36.725% {
    transform: translate(69.8242vw, 36.725vh) scale(0.3274);
  }
  to {
    transform: translate(66.10195vw, 100vh) scale(0.3274);
  }
}
.snow:nth-child(142) {
  opacity: 0.9391;
  transform: translate(96.0502vw, -10px) scale(0.4583);
  animation: fall-142 30s -27s linear infinite;
}
@keyframes fall-142 {
  67.801% {
    transform: translate(93.6062vw, 67.801vh) scale(0.4583);
  }
  to {
    transform: translate(94.8282vw, 100vh) scale(0.4583);
  }
}
.snow:nth-child(143) {
  opacity: 0.4556;
  transform: translate(80.3569vw, -10px) scale(0.4107);
  animation: fall-143 26s -25s linear infinite;
}
@keyframes fall-143 {
  38.566% {
    transform: translate(72.9347vw, 38.566vh) scale(0.4107);
  }
  to {
    transform: translate(76.6458vw, 100vh) scale(0.4107);
  }
}
.snow:nth-child(144) {
  opacity: 0.1141;
  transform: translate(67.036vw, -10px) scale(0.354);
  animation: fall-144 27s -16s linear infinite;
}
@keyframes fall-144 {
  58.92% {
    transform: translate(69.6265vw, 58.92vh) scale(0.354);
  }
  to {
    transform: translate(68.33125vw, 100vh) scale(0.354);
  }
}
.snow:nth-child(145) {
  opacity: 0.3843;
  transform: translate(94.4692vw, -10px) scale(0.5332);
  animation: fall-145 23s -23s linear infinite;
}
@keyframes fall-145 {
  46.493% {
    transform: translate(92.7778vw, 46.493vh) scale(0.5332);
  }
  to {
    transform: translate(93.6235vw, 100vh) scale(0.5332);
  }
}
.snow:nth-child(146) {
  opacity: 0.4273;
  transform: translate(74.152vw, -10px) scale(0.7349);
  animation: fall-146 11s -22s linear infinite;
}
@keyframes fall-146 {
  30.333% {
    transform: translate(69.6612vw, 30.333vh) scale(0.7349);
  }
  to {
    transform: translate(71.9066vw, 100vh) scale(0.7349);
  }
}
.snow:nth-child(147) {
  opacity: 0.0559;
  transform: translate(73.5045vw, -10px) scale(0.2448);
  animation: fall-147 29s -24s linear infinite;
}
@keyframes fall-147 {
  36.336% {
    transform: translate(83.4165vw, 36.336vh) scale(0.2448);
  }
  to {
    transform: translate(78.4605vw, 100vh) scale(0.2448);
  }
}
.snow:nth-child(148) {
  opacity: 0.7886;
  transform: translate(57.5056vw, -10px) scale(0.6392);
  animation: fall-148 13s -12s linear infinite;
}
@keyframes fall-148 {
  49.95% {
    transform: translate(55.2432vw, 49.95vh) scale(0.6392);
  }
  to {
    transform: translate(56.3744vw, 100vh) scale(0.6392);
  }
}
.snow:nth-child(149) {
  opacity: 0.5962;
  transform: translate(20.6993vw, -10px) scale(0.5331);
  animation: fall-149 18s -26s linear infinite;
}
@keyframes fall-149 {
  73.349% {
    transform: translate(27.6725vw, 73.349vh) scale(0.5331);
  }
  to {
    transform: translate(24.1859vw, 100vh) scale(0.5331);
  }
}
.snow:nth-child(150) {
  opacity: 0.4574;
  transform: translate(93.0253vw, -10px) scale(0.1542);
  animation: fall-150 19s -23s linear infinite;
}
@keyframes fall-150 {
  39.841% {
    transform: translate(87.9162vw, 39.841vh) scale(0.1542);
  }
  to {
    transform: translate(90.47075vw, 100vh) scale(0.1542);
  }
}
.snow:nth-child(151) {
  opacity: 0.5599;
  transform: translate(19.3355vw, -10px) scale(0.1325);
  animation: fall-151 11s -20s linear infinite;
}
@keyframes fall-151 {
  65.807% {
    transform: translate(14.9207vw, 65.807vh) scale(0.1325);
  }
  to {
    transform: translate(17.1281vw, 100vh) scale(0.1325);
  }
}
.snow:nth-child(152) {
  opacity: 0.4265;
  transform: translate(53.7067vw, -10px) scale(0.7666);
  animation: fall-152 25s -4s linear infinite;
}
@keyframes fall-152 {
  67.426% {
    transform: translate(56.0166vw, 67.426vh) scale(0.7666);
  }
  to {
    transform: translate(54.86165vw, 100vh) scale(0.7666);
  }
}
.snow:nth-child(153) {
  opacity: 0.2799;
  transform: translate(47.8691vw, -10px) scale(0.6709);
  animation: fall-153 13s -10s linear infinite;
}
@keyframes fall-153 {
  56.595% {
    transform: translate(56.6041vw, 56.595vh) scale(0.6709);
  }
  to {
    transform: translate(52.2366vw, 100vh) scale(0.6709);
  }
}
.snow:nth-child(154) {
  opacity: 0.835;
  transform: translate(51.8758vw, -10px) scale(0.7233);
  animation: fall-154 11s -26s linear infinite;
}
@keyframes fall-154 {
  65.893% {
    transform: translate(51.9093vw, 65.893vh) scale(0.7233);
  }
  to {
    transform: translate(51.89255vw, 100vh) scale(0.7233);
  }
}
.snow:nth-child(155) {
  opacity: 0.0042;
  transform: translate(34.496vw, -10px) scale(0.0894);
  animation: fall-155 24s -3s linear infinite;
}
@keyframes fall-155 {
  67.82% {
    transform: translate(27.0169vw, 67.82vh) scale(0.0894);
  }
  to {
    transform: translate(30.75645vw, 100vh) scale(0.0894);
  }
}
.snow:nth-child(156) {
  opacity: 0.1473;
  transform: translate(48.879vw, -10px) scale(0.4657);
  animation: fall-156 15s -27s linear infinite;
}
@keyframes fall-156 {
  58.299% {
    transform: translate(44.7576vw, 58.299vh) scale(0.4657);
  }
  to {
    transform: translate(46.8183vw, 100vh) scale(0.4657);
  }
}
.snow:nth-child(157) {
  opacity: 0.6379;
  transform: translate(4.1963vw, -10px) scale(0.0564);
  animation: fall-157 11s -18s linear infinite;
}
@keyframes fall-157 {
  68.43% {
    transform: translate(9.3232vw, 68.43vh) scale(0.0564);
  }
  to {
    transform: translate(6.75975vw, 100vh) scale(0.0564);
  }
}
.snow:nth-child(158) {
  opacity: 0.7091;
  transform: translate(54.7076vw, -10px) scale(0.6802);
  animation: fall-158 13s -22s linear infinite;
}
@keyframes fall-158 {
  53.834% {
    transform: translate(57.1226vw, 53.834vh) scale(0.6802);
  }
  to {
    transform: translate(55.9151vw, 100vh) scale(0.6802);
  }
}
.snow:nth-child(159) {
  opacity: 0.3918;
  transform: translate(89.9876vw, -10px) scale(0.2218);
  animation: fall-159 20s -25s linear infinite;
}
@keyframes fall-159 {
  51.363% {
    transform: translate(98.1264vw, 51.363vh) scale(0.2218);
  }
  to {
    transform: translate(94.057vw, 100vh) scale(0.2218);
  }
}
.snow:nth-child(160) {
  opacity: 0.9986;
  transform: translate(20.2868vw, -10px) scale(0.3492);
  animation: fall-160 18s -15s linear infinite;
}
@keyframes fall-160 {
  78.364% {
    transform: translate(27.2262vw, 78.364vh) scale(0.3492);
  }
  to {
    transform: translate(23.7565vw, 100vh) scale(0.3492);
  }
}
.snow:nth-child(161) {
  opacity: 0.9453;
  transform: translate(90.8418vw, -10px) scale(0.2074);
  animation: fall-161 16s -18s linear infinite;
}
@keyframes fall-161 {
  71.736% {
    transform: translate(96.3954vw, 71.736vh) scale(0.2074);
  }
  to {
    transform: translate(93.6186vw, 100vh) scale(0.2074);
  }
}
.snow:nth-child(162) {
  opacity: 0.0749;
  transform: translate(92.9302vw, -10px) scale(0.2913);
  animation: fall-162 26s -29s linear infinite;
}
@keyframes fall-162 {
  36.998% {
    transform: translate(84.1485vw, 36.998vh) scale(0.2913);
  }
  to {
    transform: translate(88.53935vw, 100vh) scale(0.2913);
  }
}
.snow:nth-child(163) {
  opacity: 0.5548;
  transform: translate(94.567vw, -10px) scale(0.8456);
  animation: fall-163 14s -9s linear infinite;
}
@keyframes fall-163 {
  49.991% {
    transform: translate(91.178vw, 49.991vh) scale(0.8456);
  }
  to {
    transform: translate(92.8725vw, 100vh) scale(0.8456);
  }
}
.snow:nth-child(164) {
  opacity: 0.8839;
  transform: translate(0.5327vw, -10px) scale(0.0588);
  animation: fall-164 24s -10s linear infinite;
}
@keyframes fall-164 {
  65.405% {
    transform: translate(10.2982vw, 65.405vh) scale(0.0588);
  }
  to {
    transform: translate(5.41545vw, 100vh) scale(0.0588);
  }
}
.snow:nth-child(165) {
  opacity: 0.5336;
  transform: translate(89.9884vw, -10px) scale(0.599);
  animation: fall-165 11s -23s linear infinite;
}
@keyframes fall-165 {
  76.616% {
    transform: translate(87.3648vw, 76.616vh) scale(0.599);
  }
  to {
    transform: translate(88.6766vw, 100vh) scale(0.599);
  }
}
.snow:nth-child(166) {
  opacity: 0.9221;
  transform: translate(49.3119vw, -10px) scale(0.377);
  animation: fall-166 28s -26s linear infinite;
}
@keyframes fall-166 {
  34.374% {
    transform: translate(40.7838vw, 34.374vh) scale(0.377);
  }
  to {
    transform: translate(45.04785vw, 100vh) scale(0.377);
  }
}
.snow:nth-child(167) {
  opacity: 0.5509;
  transform: translate(17.4958vw, -10px) scale(0.1181);
  animation: fall-167 30s -14s linear infinite;
}
@keyframes fall-167 {
  67.32% {
    transform: translate(26.1115vw, 67.32vh) scale(0.1181);
  }
  to {
    transform: translate(21.80365vw, 100vh) scale(0.1181);
  }
}
.snow:nth-child(168) {
  opacity: 0.0518;
  transform: translate(21.6606vw, -10px) scale(0.4736);
  animation: fall-168 21s -6s linear infinite;
}
@keyframes fall-168 {
  68.934% {
    transform: translate(21.6847vw, 68.934vh) scale(0.4736);
  }
  to {
    transform: translate(21.67265vw, 100vh) scale(0.4736);
  }
}
.snow:nth-child(169) {
  opacity: 0.5142;
  transform: translate(19.5638vw, -10px) scale(0.2031);
  animation: fall-169 29s -29s linear infinite;
}
@keyframes fall-169 {
  79.148% {
    transform: translate(26.4758vw, 79.148vh) scale(0.2031);
  }
  to {
    transform: translate(23.0198vw, 100vh) scale(0.2031);
  }
}
.snow:nth-child(170) {
  opacity: 0.4776;
  transform: translate(11.3704vw, -10px) scale(0.2511);
  animation: fall-170 21s -19s linear infinite;
}
@keyframes fall-170 {
  65.035% {
    transform: translate(3.5003vw, 65.035vh) scale(0.2511);
  }
  to {
    transform: translate(7.43535vw, 100vh) scale(0.2511);
  }
}
.snow:nth-child(171) {
  opacity: 0.2777;
  transform: translate(86.6855vw, -10px) scale(0.1352);
  animation: fall-171 28s -16s linear infinite;
}
@keyframes fall-171 {
  66.727% {
    transform: translate(89.9722vw, 66.727vh) scale(0.1352);
  }
  to {
    transform: translate(88.32885vw, 100vh) scale(0.1352);
  }
}
.snow:nth-child(172) {
  opacity: 0.7161;
  transform: translate(77.6079vw, -10px) scale(0.3522);
  animation: fall-172 17s -2s linear infinite;
}
@keyframes fall-172 {
  58% {
    transform: translate(68.8088vw, 58vh) scale(0.3522);
  }
  to {
    transform: translate(73.20835vw, 100vh) scale(0.3522);
  }
}
.snow:nth-child(173) {
  opacity: 0.7562;
  transform: translate(84.5446vw, -10px) scale(0.9048);
  animation: fall-173 29s -27s linear infinite;
}
@keyframes fall-173 {
  65.284% {
    transform: translate(87.5854vw, 65.284vh) scale(0.9048);
  }
  to {
    transform: translate(86.065vw, 100vh) scale(0.9048);
  }
}
.snow:nth-child(174) {
  opacity: 0.1628;
  transform: translate(81.5318vw, -10px) scale(0.3405);
  animation: fall-174 28s -22s linear infinite;
}
@keyframes fall-174 {
  36.849% {
    transform: translate(87.5174vw, 36.849vh) scale(0.3405);
  }
  to {
    transform: translate(84.5246vw, 100vh) scale(0.3405);
  }
}
.snow:nth-child(175) {
  opacity: 0.6746;
  transform: translate(98.5152vw, -10px) scale(0.8745);
  animation: fall-175 21s -16s linear infinite;
}
@keyframes fall-175 {
  42.756% {
    transform: translate(106.493vw, 42.756vh) scale(0.8745);
  }
  to {
    transform: translate(102.5041vw, 100vh) scale(0.8745);
  }
}
.snow:nth-child(176) {
  opacity: 0.4982;
  transform: translate(21.7685vw, -10px) scale(0.1043);
  animation: fall-176 20s -23s linear infinite;
}
@keyframes fall-176 {
  75.478% {
    transform: translate(27.844vw, 75.478vh) scale(0.1043);
  }
  to {
    transform: translate(24.80625vw, 100vh) scale(0.1043);
  }
}
.snow:nth-child(177) {
  opacity: 0.7807;
  transform: translate(1.3408vw, -10px) scale(0.9181);
  animation: fall-177 26s -10s linear infinite;
}
@keyframes fall-177 {
  60.455% {
    transform: translate(-4.5858vw, 60.455vh) scale(0.9181);
  }
  to {
    transform: translate(-1.6225vw, 100vh) scale(0.9181);
  }
}
.snow:nth-child(178) {
  opacity: 0.6867;
  transform: translate(56.7199vw, -10px) scale(0.5505);
  animation: fall-178 19s -20s linear infinite;
}
@keyframes fall-178 {
  55.225% {
    transform: translate(52.5038vw, 55.225vh) scale(0.5505);
  }
  to {
    transform: translate(54.61185vw, 100vh) scale(0.5505);
  }
}
.snow:nth-child(179) {
  opacity: 0.1828;
  transform: translate(81.6125vw, -10px) scale(0.8934);
  animation: fall-179 22s -4s linear infinite;
}
@keyframes fall-179 {
  30.58% {
    transform: translate(89.9376vw, 30.58vh) scale(0.8934);
  }
  to {
    transform: translate(85.77505vw, 100vh) scale(0.8934);
  }
}
.snow:nth-child(180) {
  opacity: 0.2322;
  transform: translate(46.6344vw, -10px) scale(0.5237);
  animation: fall-180 22s -23s linear infinite;
}
@keyframes fall-180 {
  71.06% {
    transform: translate(48.4933vw, 71.06vh) scale(0.5237);
  }
  to {
    transform: translate(47.56385vw, 100vh) scale(0.5237);
  }
}
.snow:nth-child(181) {
  opacity: 0.8695;
  transform: translate(31.2461vw, -10px) scale(0.0978);
  animation: fall-181 18s -28s linear infinite;
}
@keyframes fall-181 {
  33.699% {
    transform: translate(26.7376vw, 33.699vh) scale(0.0978);
  }
  to {
    transform: translate(28.99185vw, 100vh) scale(0.0978);
  }
}
.snow:nth-child(182) {
  opacity: 0.0925;
  transform: translate(52.0454vw, -10px) scale(0.6473);
  animation: fall-182 28s -7s linear infinite;
}
@keyframes fall-182 {
  41.366% {
    transform: translate(59.4816vw, 41.366vh) scale(0.6473);
  }
  to {
    transform: translate(55.7635vw, 100vh) scale(0.6473);
  }
}
.snow:nth-child(183) {
  opacity: 0.439;
  transform: translate(5.3221vw, -10px) scale(0.9158);
  animation: fall-183 15s -15s linear infinite;
}
@keyframes fall-183 {
  37.992% {
    transform: translate(5.8416vw, 37.992vh) scale(0.9158);
  }
  to {
    transform: translate(5.58185vw, 100vh) scale(0.9158);
  }
}
.snow:nth-child(184) {
  opacity: 0.2582;
  transform: translate(38.9881vw, -10px) scale(0.2953);
  animation: fall-184 14s -2s linear infinite;
}
@keyframes fall-184 {
  68.066% {
    transform: translate(36.891vw, 68.066vh) scale(0.2953);
  }
  to {
    transform: translate(37.93955vw, 100vh) scale(0.2953);
  }
}
.snow:nth-child(185) {
  opacity: 0.2875;
  transform: translate(75.2591vw, -10px) scale(0.5403);
  animation: fall-185 19s -6s linear infinite;
}
@keyframes fall-185 {
  51.545% {
    transform: translate(68.9761vw, 51.545vh) scale(0.5403);
  }
  to {
    transform: translate(72.1176vw, 100vh) scale(0.5403);
  }
}
.snow:nth-child(186) {
  opacity: 0.9559;
  transform: translate(72.029vw, -10px) scale(0.2562);
  animation: fall-186 17s -28s linear infinite;
}
@keyframes fall-186 {
  56.282% {
    transform: translate(65.8462vw, 56.282vh) scale(0.2562);
  }
  to {
    transform: translate(68.9376vw, 100vh) scale(0.2562);
  }
}
.snow:nth-child(187) {
  opacity: 0.4519;
  transform: translate(37.1791vw, -10px) scale(0.8494);
  animation: fall-187 16s -29s linear infinite;
}
@keyframes fall-187 {
  43.652% {
    transform: translate(46.0092vw, 43.652vh) scale(0.8494);
  }
  to {
    transform: translate(41.59415vw, 100vh) scale(0.8494);
  }
}
.snow:nth-child(188) {
  opacity: 0.0846;
  transform: translate(43.8122vw, -10px) scale(0.6106);
  animation: fall-188 12s -13s linear infinite;
}
@keyframes fall-188 {
  56.238% {
    transform: translate(48.5952vw, 56.238vh) scale(0.6106);
  }
  to {
    transform: translate(46.2037vw, 100vh) scale(0.6106);
  }
}
.snow:nth-child(189) {
  opacity: 0.8332;
  transform: translate(5.2984vw, -10px) scale(0.2825);
  animation: fall-189 20s -28s linear infinite;
}
@keyframes fall-189 {
  71.479% {
    transform: translate(9.7257vw, 71.479vh) scale(0.2825);
  }
  to {
    transform: translate(7.51205vw, 100vh) scale(0.2825);
  }
}
.snow:nth-child(190) {
  opacity: 0.7386;
  transform: translate(17.6124vw, -10px) scale(0.4527);
  animation: fall-190 22s -16s linear infinite;
}
@keyframes fall-190 {
  34.926% {
    transform: translate(13.0738vw, 34.926vh) scale(0.4527);
  }
  to {
    transform: translate(15.3431vw, 100vh) scale(0.4527);
  }
}
.snow:nth-child(191) {
  opacity: 0.8428;
  transform: translate(41.3627vw, -10px) scale(0.8685);
  animation: fall-191 11s -29s linear infinite;
}
@keyframes fall-191 {
  47.545% {
    transform: translate(42.0302vw, 47.545vh) scale(0.8685);
  }
  to {
    transform: translate(41.69645vw, 100vh) scale(0.8685);
  }
}
.snow:nth-child(192) {
  opacity: 0.8429;
  transform: translate(98.509vw, -10px) scale(0.1406);
  animation: fall-192 22s -17s linear infinite;
}
@keyframes fall-192 {
  65.575% {
    transform: translate(102.6145vw, 65.575vh) scale(0.1406);
  }
  to {
    transform: translate(100.56175vw, 100vh) scale(0.1406);
  }
}
.snow:nth-child(193) {
  opacity: 0.4125;
  transform: translate(85.9748vw, -10px) scale(0.5206);
  animation: fall-193 22s -30s linear infinite;
}
@keyframes fall-193 {
  36.189% {
    transform: translate(82.9732vw, 36.189vh) scale(0.5206);
  }
  to {
    transform: translate(84.474vw, 100vh) scale(0.5206);
  }
}
.snow:nth-child(194) {
  opacity: 0.3794;
  transform: translate(78.5256vw, -10px) scale(0.7729);
  animation: fall-194 19s -10s linear infinite;
}
@keyframes fall-194 {
  31.942% {
    transform: translate(79.6873vw, 31.942vh) scale(0.7729);
  }
  to {
    transform: translate(79.10645vw, 100vh) scale(0.7729);
  }
}
.snow:nth-child(195) {
  opacity: 0.3336;
  transform: translate(21.1459vw, -10px) scale(0.5259);
  animation: fall-195 28s -10s linear infinite;
}
@keyframes fall-195 {
  43.024% {
    transform: translate(31.0204vw, 43.024vh) scale(0.5259);
  }
  to {
    transform: translate(26.08315vw, 100vh) scale(0.5259);
  }
}
.snow:nth-child(196) {
  opacity: 0.7559;
  transform: translate(77.2309vw, -10px) scale(0.9602);
  animation: fall-196 18s -18s linear infinite;
}
@keyframes fall-196 {
  71.042% {
    transform: translate(68.1002vw, 71.042vh) scale(0.9602);
  }
  to {
    transform: translate(72.66555vw, 100vh) scale(0.9602);
  }
}
.snow:nth-child(197) {
  opacity: 0.8349;
  transform: translate(0.0821vw, -10px) scale(0.552);
  animation: fall-197 22s -1s linear infinite;
}
@keyframes fall-197 {
  44.582% {
    transform: translate(-8.5557vw, 44.582vh) scale(0.552);
  }
  to {
    transform: translate(-4.2368vw, 100vh) scale(0.552);
  }
}
.snow:nth-child(198) {
  opacity: 0.5143;
  transform: translate(60.0076vw, -10px) scale(0.8639);
  animation: fall-198 25s -20s linear infinite;
}
@keyframes fall-198 {
  37.055% {
    transform: translate(62.9673vw, 37.055vh) scale(0.8639);
  }
  to {
    transform: translate(61.48745vw, 100vh) scale(0.8639);
  }
}
.snow:nth-child(199) {
  opacity: 0.9279;
  transform: translate(82.6948vw, -10px) scale(0.9482);
  animation: fall-199 19s -30s linear infinite;
}
@keyframes fall-199 {
  40.763% {
    transform: translate(79.4554vw, 40.763vh) scale(0.9482);
  }
  to {
    transform: translate(81.0751vw, 100vh) scale(0.9482);
  }
}
.snow:nth-child(200) {
  opacity: 0.9862;
  transform: translate(7.2327vw, -10px) scale(0.9245);
  animation: fall-200 25s -27s linear infinite;
}
@keyframes fall-200 {
  54.943% {
    transform: translate(-0.369vw, 54.943vh) scale(0.9245);
  }
  to {
    transform: translate(3.43185vw, 100vh) scale(0.9245);
  }
}
