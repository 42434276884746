body {
  margin: 0;
}

img {
  max-width: 100%;
}

.App {
  max-width: 768px;
  margin: 0 auto;
  text-align: center;
  font-family: 'Nanum Myeongjo', serif;
  color: #5e5959;
  font-size: 24px;
}

.container {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.container--visible {
  visibility: visible;
  opacity: 1;
}

.main {
  position: relative;
}

.main-title {
  border: 1px solid #dbe5e4;
  padding: 20px 0px 20px 0px;
  width: 200px;
  margin: 0 auto;
  margin-bottom: 14px;
  margin-top: 14px;
  font-weight: bold;
}

.wedding-invitation {
  margin-bottom: 20px;
  font-size: 28px;
}

.invitation-card {
  line-height: 30px;
  font-size: 20px;
  white-space: pre-wrap;
  word-break: keep-all;
  word-wrap: break-word;
}

.v-line {
  width: 100px;
  height: 50px;
  border-right: 2px solid #dbe5e4;
  margin-bottom: 14px;
}

.main-description {
  margin-top: 14px;
}

.main-date {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 4px;
}

.h-line {
  width: 200px;
  height: 2px;
  border-bottom: 2px solid #dbe5e4;
  margin: 0 auto;
}

.main-location {
  font-size: 18px;
  margin-top: 4px;
  font-weight: bold;
}

.invitation-card {
  padding: 20px;
  margin-top: 12px;
}

.carousel {
  margin-top: 14px;
  margin-bottom: 30px;
}

.map {
  margin: 0 auto;
  margin-top: 14px;
  width: 90%;
  height: 400px;
}

.map-container {
  margin-top: 14px;
}

.map-container, .share {
  font-family: 'Noto Sans KR', sans-serif;
}

.map-title {
  margin-top: 14px;
  font-size: 22px;
}

.map-buttons {
  margin-top: 14px;
}

.map-buttons img {
  width: 68px;
}

.map-buttons span {
  font-size: 18px;
  display: block;
  margin-top: -6px;
}

.button {
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.bmw {
  margin-bottom: 20px;
}

.bmw-element {
  padding: 10px;
  border-bottom: 1px solid #dbe5e4;
}

.bmw-element span {
  display: block;
  text-align: left;
  font-size: 16px;
}

.bmw-title {
  font-weight: bold;
  font-size: 20px;
}

.share {
  font-size: 14px;
  font-weight: bold;
  margin: 0 auto;
  margin-bottom: 20px;
  border: 1px solid #dbe5e4;
  width: 140px;
  padding: 10px;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.loading {
  height: 100vh;
}

.loading img {
  margin-top: 250px;
}

.parents-container {
  margin-bottom: 40px;
}

.parents-container span {
  font-size: 20px;
}

.parents-container .parents-title {
  display: block;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 4px;
}

.parents-container div:first-child {
  margin-right: 20px;
}

.parents {
  margin-bottom: 14px;
  display: inline-block;
}
